.Feedback {
    display: flex;
    flex-direction: column;
    flex: 1
}

.Feedback .instrucoes {
    text-align: justify;
    font-size: 13px;
    line-height: 1.5;
}

.Feedback .formulario {
    margin-top: 40px;
}

.Feedback .formulario.texto_livre {
    flex: 1;
    padding-bottom: 20px;
}

.Feedback .formulario.texto_livre textarea {
    height: 100%;
    display: block;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-radius: 17px;
    border-color: #F4F4F4;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.04);
    background: white;
    outline: none;
    padding: 15px;
    width: 100%;
    min-height: 100px;
}