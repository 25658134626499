.Orador {
    padding: 0;
}

.Orador .HeaderOrador {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Orador .HeaderOrador span.img {
    width: 120px;
    height: 120px;
    display: block;
    margin-right: 15px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    flex: none;
}

.Orador .HeaderOrador h2{
    flex: 1;
}

.Orador .content p {
    font-size: 16px;
    line-height: 1.5;
}