.Notificacoes {
    padding-bottom: 25px;
}

.Notificacoes .Notificacao {
    border-bottom: 1px solid #e5e5e5;
    border-width: 0.5px;
    padding-bottom: 12px;
    display: flex;
    align-items: stretch;
}

.Notificacoes .Notificacao:not(:first-child) {
    margin-top: 12px;
}

.Notificacoes .Notificacao > div {
    flex: 1;
}

.Notificacoes .Notificacao .button {
    display: flex;
    align-items: center;
    margin-right: -5px;
}

.Notificacoes .Notificacao > div h4 {
    font-weight: normal;
}

.Notificacoes .Notificacao > div p:not(.time) {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.Notificacoes .Notificacao > div p.time {
    font-size: 12px;
    margin-top: 3px;
}

.Notificacoes .Notificacao > div .estado {
    background-color: #488bde;
    width: 7px;
    height: 7px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
}

.Notificacoes .Notificacao > div .estado.lida {
    background-color: unset;
    border: solid 1px #488bde;
}