.Info {
    padding-bottom: 25px;
}

.Info .header_image {
    height: 56vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 75%, cover;
    max-height: 50vh;
}

.Info .dias {
    display: flex;
    align-items: stretch;
    margin: 25px -6px;
}

.Info .dias .dia {
    flex: 1;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 20px rgba(1, 1, 1, 0.08);
    background: white;
    border-radius: 15px;
    margin: 0 6px;
}

.Info .dias .dia p {
    text-align: center;
    color: #011146;
    font-weight: bold;
    font-size: 15px;
}

.Info .description > * {
    list-style: none;
    padding: 0;
    margin-top: 15px;
}

.Info .description * {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.Info .promotores, .Info .parceiros {
    margin: 0 -5px;
    text-align: center;
}

.Info .promotores .promotor, .Info .parceiros .parceiro {
    width: calc(33.333% - 10px);
    display: inline-block;
    padding: 3.5% 4%;
    box-shadow: 0 5px 15px rgba(1, 1, 1, 0.08);
    border-radius: 15px;
    margin: 5px;
    background: white;
}

.Info .promotores .promotor.destacado {
    width: calc(50% - 10px);
    padding: 5% 7%;
}

.Info .promotores .promotor span, .Info .parceiros .parceiro span {
    padding-bottom: 60%;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.ios .Info .header_image, .android .Info .header_image {
    background-position: center 73%, center;
    height: calc(56vw + 20px);
}