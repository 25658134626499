.FormularioFeedback {
    display: flex;
    flex-direction: column;
    flex: 1
}

.FormularioFeedback .instrucoes {
    text-align: justify;
    font-size: 13px;
    line-height: 1.5;
}

.FormularioFeedback .formulario {

}

.FormularioFeedback .formulario.esc_multipla .pergunta {
    margin-top: 25px;
}

.FormularioFeedback .formulario.esc_multipla .pergunta h4 {
    font-size: 14px;
    margin-bottom: 12px;
}

.FormularioFeedback .formulario.esc_multipla .pergunta .opcoes {
    margin-top: 10px;
}

.FormularioFeedback .formulario.esc_multipla .pergunta .opcoes .opcao {
    padding: 9px 16px;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    border-color: #F4F4F4;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.04);
    background: white;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}


.FormularioFeedback .formulario.esc_multipla .pergunta .opcoes .opcao div.botao {
    display: flex;
    align-items: center;
}

.FormularioFeedback .formulario.esc_multipla .pergunta .opcoes .opcao p {
    font-size: 13px;
    margin-top: 0;
    flex: 1;
    margin-right: 15px;
    text-align: left;
}

.FormularioFeedback .formulario.esc_multipla .pergunta .opcoes .opcao .select-opcao {
    display: block;
    width: 22px;
    height: 22px;
    background-color: white;
    border-radius: 50%;
    border: #b0b0b0 solid 1px;
    border-width: 0.5px;
    transition: background-color 0.1s, border-color 0.1s;
}

.FormularioFeedback .formulario.esc_multipla .pergunta .opcoes .opcao .select-opcao.active {
    background-color: #707070;
    border-color: #707070;
}

.FormularioFeedback .formulario.imagem {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 25px;
}

.FormularioFeedback .formulario.imagem .wrapper_widget_imagem {
    position: relative;
    flex: 1;
    background: white;
}

.FormularioFeedback .formulario.imagem .wrapper_widget_imagem.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
}

.FormularioFeedback .formulario.imagem .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vw;
    max-height: 400px;
    padding-bottom: 50px;
}

.FormularioFeedback .formulario.imagem .imagem {
    position: relative;
    border-radius: 17px;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-color: #F4F4F4;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.04);
    background: white;
}

.FormularioFeedback .formulario.imagem .imagem img {
    display: block;
}

.FormularioFeedback .formulario.imagem span.preview_imagem {
    display: block;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
}

.FormularioFeedback .formulario.imagem .react-html5-camera-photo video {
    display: block !important;
    width: 100%;
}

.FormularioFeedback .formulario.imagem .react-html5-camera-photo #container-circles {
    display: none;
}

.FormularioFeedback .formulario.imagem .react-html5-camera-photo img {
    display: none !important;
}

.FormularioFeedback .formulario.imagem .react-html5-camera-photo #white-flash.normal {
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.FormularioFeedback .formulario.imagem .actions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
}

.FormularioFeedback .formulario.imagem .switch {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.FormularioFeedback .formulario.imagem .toggle_fullscreen {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
}

.FormularioFeedback .formulario.imagem .actions p {
    margin-top: 10px;
}

.FormularioFeedback .formulario.imagem .actions p:not(.with_placeholder) {
    color: white;
    text-shadow: 0 0 4px black;
}

.FormularioFeedback .formulario.imagem .actions p input {
    display: none;
}

.FormularioFeedback .formulario.texto_livre {
    flex: 1;
    padding-bottom: 40px;
    margin-top: 40px;
}

.FormularioFeedback .formulario.texto_livre textarea {
    height: 100%;
    display: block;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-radius: 17px;
    border-color: #F4F4F4;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.04);
    background: white;
    outline: none;
    padding: 15px;
    width: 100%;
    min-height: 200px;
}