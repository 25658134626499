.PersonalizarPrograma .header {
    margin-bottom: 25px;
}

.PersonalizarPrograma .header h2 {
    text-transform: uppercase;
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
}

.PersonalizarPrograma .header p {
    width: 90%;
    text-align: center;
    margin: auto;
    margin-top: 10px;
}

.PersonalizarPrograma .sessoes .bloco_dia:not(:first-child) {
    margin-top: 40px;
}

.PersonalizarPrograma .sessoes .data_slot {
    font-weight: normal;
    font-size: 20px;
}

.PersonalizarPrograma .sessoes .hora_slot {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 25px;
}

.PersonalizarPrograma .sessoes .sessao {
    padding: 12px 16px;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    border-color: #F4F4F4;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.04);
    background: white;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: stretch;
}

.PersonalizarPrograma .sessoes .sessao.desativada {
    opacity: 0.5;
    pointer-events: none;
}

.PersonalizarPrograma .sessoes .sessao > div:first-child {
    flex: 1;
    padding-right: 10px;
}

.PersonalizarPrograma .sessoes .sessao div.botao {
    display: flex;
    align-items: center;
}

.PersonalizarPrograma .sessoes .sessao div h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 2px;
}

.PersonalizarPrograma .sessoes .sessao div h3 > svg {
    position: relative;
    top: 2px;
}

.PersonalizarPrograma .sessoes .sessao div h4 {
    font-weight: normal;
    font-size: 13px;
}

.PersonalizarPrograma .sessoes .sessao div p {
    font-size: 12px;
    margin-top: 3px;
}

.PersonalizarPrograma .sessoes .sessao .toggle-sessao {
    display: block;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    border: #b0b0b0 solid 1px;
    border-width: 0.5px;
    transition: background-color 0.1s, border-color 0.1s;
}

.PersonalizarPrograma .sessoes .sessao .toggle-sessao.active {
    background-color: #51B27A;
    border-color: #51B27A;
}

.PersonalizarPrograma .sessoes .sessao .toggle-sessao.default {
    background-color: #dedede;
    border-color: #dedede;
}
