.Codigo {
    padding: 25px 0;
    min-height: 91vh;
}

.Codigo .avatar {
    border-radius: 50%;
    width: 35%;
    margin: 0 auto;
    margin-top: 20px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
}

.Codigo .DetalhesUtilizador {
    text-align: center;
}

.Codigo .DetalhesUtilizador h2 {
    font-weight: bold;
    font-size: 28px;
    margin-top: 10px;
}

.Codigo .DetalhesUtilizador h3 {
    font-weight: normal;
    font-size: 16px;
}

.Codigo .qrcode {
    width: 70%;
    margin: 40px auto;
    height: auto;
    max-width: 450px;
}