.btn {
    cursor: pointer;
    padding: 3px 14px;
    border-radius: 90px;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    margin: 0 auto;
    outline: none;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    min-width: 65%;
    background: #B76E17;
    background: linear-gradient(67deg, rgb(232, 179, 109) 0%, rgb(183, 110, 23) 100%);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn:hover {
    opacity: 0.95;
}

.btn svg, .btn .svg {
    fill: white;
}

.btn svg:last-child, .btn .svg:last-child {
    margin-right: 0;
}

.btn.btn-primary {
    box-shadow: 0 10px 10px rgba(68, 166, 255, 0.20);
    background: rgb(81, 143, 199);
    background: linear-gradient(67deg, rgba(81, 143, 199, 1) 0%, rgba(1, 17, 70, 1) 100%);
}

.btn.btn-primary:active, .btn.btn-primary:hover {
    box-shadow: 0 10px 10px rgba(68, 166, 255, 0.50);
}

.btn.btn-danger {
    box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
    background-color: #f44336;
}

.btn.btn-danger:active, .btn.btn-danger:hover {
    box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
    background-color: #f44336;
}

.btn.btn-success {
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
    background-color: #7ac057;
}

.btn.btn-success:active, .btn.btn-success:hover {
    box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
    background-color: #6db357;
}

.btn.btn-stroke {
    background: none;
    border-width: 2px;
    border-style: solid;
    color: initial;
    box-shadow: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    min-height: 28px;
}

.btn.btn-stroke.btn-primary {
    border-color: #00014F;
    color: #00014F;
}

.btn.btn-stroke.btn-primary svg, .btn.btn-stroke.btn-primary .svg {
    fill: #00014F;
    height: 15px;
    width: 15px;
    margin-right: 3px;
}

.btn.btn-block {
    width: 100%;
    display: block;
}

.btn-sm {
    padding: 0 18px;
    font-size: 13px;
    min-width: 0;
    font-weight: normal;
    box-shadow: 0 5px 10px rgba(68, 166, 255, 0.20);
    min-height: 23px;
}

.btn-sm.btn-primary:active, .btn-sm.btn-primary:hover {
    box-shadow: 0 5px 10px rgba(68, 166, 255, 0.50);
}

.btn-xs {
    padding: 0 8px;
    font-size: 12px;
    text-transform: none;
    min-width: 0;
    min-height: 26px;
    box-shadow: 0 4px 5px -2px rgba(68, 166, 255, 0.20);
}

.btn-xs.btn-primary:active, .btn-xs.btn-primary:hover {
    box-shadow: 0 4px 5px -2px rgba(68, 166, 255, 0.50);
}

.btn.disabled {
    opacity: 0.6;
    pointer-events: none;
}