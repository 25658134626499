.Programa {
    padding: 25px 0;
}

.Programa .header {
    display: flex;
    margin-bottom: 25px;
}

.Programa .header .SlidingToggle {
    flex: 1;
}

.Programa .header .FilterToggle {
    margin-left: 10px;
}