.teamWrapper {
    min-height: calc(100vh - 45px);
}

.teamWrapper:not(:first-child) {
    margin-top: 30px;
}

.teamImage {
    margin: -16px;
    margin-bottom: 14px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.teamImage h1 {
    font-size: 130px;
    padding-top: 10px;
    text-align: center;
    color: white;
}

.teamImage h2 {
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 40px;
    padding-bottom: 30px;
}

.BlocoUtilizador {
    padding: 10px 5px;
    overflow: hidden;
}

.BlocoUtilizador > img {
    width: 70px;
    height: 70px;
    float: left;
    border-radius: 5px;
}

.BlocoUtilizador > div {
    width: calc(100% - 80px);
    float: left;
    padding-left: 10px;
}

.BlocoUtilizador svg {
    position: relative;
    top: 2px;
}

.BlocoUtilizador h2 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 3px;
}

.BlocoUtilizador p {
    font-size: 15px;
    margin: 0;
    text-align: left;
}

.teamImage .editPhoto {
    position: absolute;
    top: 10px;
    right: 10px;
    fill: white;
    cursor: pointer;
}

.imagemInstagram img {
    border-radius: 5px;
    margin-top: 10px;
}