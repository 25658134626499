.FilterToggle {
    padding: 10px 15px;
    border-radius: 15px;
    background: #f5f5f5;
}

.FilterToggle .inner {
    height: 30px;
    display: flex;
    align-items: center;
}

.FilterToggle .inner img {
    height: 16px;
    margin-right: 5px;
}

.FilterToggle .inner > div {
    flex: 1;
    color: #011146
}

.FilterToggle .inner > div p {
    margin: 0;
    font-size: 10px;
}

.FilterToggle .inner > div h4 {
    margin: 0;
    font-size: 10px;
}
