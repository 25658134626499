.BottomTabNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: table;
    background-color: white;
    z-index: 2;
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    border-top: #dadada 1px solid;
    min-height: 55px;
}

.ios .BottomTabNavigation{
    padding-bottom: 15px
}

.BottomTabNavigation .BottomTabNavigationButton {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 8px;
}

.BottomTabNavigation .BottomTabNavigationButton:hover, .BottomTabNavigation .BottomTabNavigationButton.active {
    cursor: pointer;
    background-color: rgba(1, 17, 70, 0.05);
}

.BottomTabNavigation .BottomTabNavigationButton svg {
    width: 30px;
    height: 30px;
    fill: gray;
}

.BottomTabNavigation .BottomTabNavigationButton.active svg {
    fill: #011146;
}