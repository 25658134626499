.PerfilEditar {
    padding: 25px 0;
}

.CabecalhoPerfilEditar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.CabecalhoPerfilEditar .avatar {
    border-radius: 50%;
    width: 130px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
}

.CabecalhoPerfilEditar .btn {
    margin-top: -6px;
}

.PerfilEditar .checkbox-toggle {

}

.PerfilEditar .checkbox-toggle input {
    margin-left: -30px;
}