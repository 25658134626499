.SlotSessoes:not(:first-child) {
    margin-top: 10px;
}

.SlotSessoes:last-child {
    margin-bottom: 40px;
}

.SlotSessoes .sessoes {
}

.SlotSessoes .sessoes .tema {
    margin-bottom: 5px;
}

.SlotSessoes .sessoes .tema > .label {
    margin-left: 46px;
    padding-left: 2px;
    margin-bottom: 3px;
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
}

.SlotSessoes .sessoes .wrapperSessao {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.SlotSessoes .sessoes .wrapperSessao .hora {
    min-width: 46px;
    text-align: right;
    padding-right: 8px;
    font-size: 13px;
    margin-bottom: 2px;
}

.SlotSessoes .sessoes .wrapperSessao .sessao {
    flex: 1;
    padding: 10px 16px;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    border-color: #dbdbdb;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
    background: white;
    cursor: pointer;
    display: flex;
    align-items: stretch;
}

.SlotSessoes .sessoes .wrapperSessao .sessao > div:first-child {
    flex: 1
}

.SlotSessoes .sessoes .wrapperSessao .sessao p {
    margin: 0;
}

.SlotSessoes .sessoes .wrapperSessao .sessao .categoria {
    font-size: 10px;
    font-weight: 300;
}

.SlotSessoes .sessoes .wrapperSessao .sessao .edit {
    display: flex;
    align-items: center;
    margin-left: 10px;
}