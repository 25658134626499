.Splashscreen {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    padding: 50px 0;
}

.Splashscreen .logo {
    background-size: contain;
    height: 600px;
    height: 80vh;
    width: 80%;
    margin: auto;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(.45, .05, .55, .95);
    animation-timing-function: cubic-bezier(.45, .05, .55, .95);
}

.Splashscreen.withBox .logo {
    height: 160px;
}

.Splashscreen .splashBox {
    max-width: 600px;
    margin: auto;
    margin-top: 50px;
}

.Splashscreen > .container {
    padding: 0 15px;
}