@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

body, html {
    font-family: 'Open Sans', sans-serif;
    color: #3E3E3E;
}

h1, h2, h3, h4, h5 {
    margin: 0;
}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

p {
    margin: 0;
    margin-top: 5px;
    text-align: justify;
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 300;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a {
    text-decoration: none;
    color: #0081F6;
    cursor: pointer;
}

p a[href] {
    color: #009BDF;
}

p a[href]:hover {
    text-decoration: underline;
}

.text-danger {
    color: red;
    list-style: none;
}

.text-success {
    color: #51B27A;
}

.text-warning {
    color: #CEA600;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.color-primary {
    color: #00014F
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.section-title {
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #011146;
    text-transform: uppercase;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.section-title.separator {
    padding-bottom: 8px;
    border-bottom: #c6c6c8 solid 1px;
    border-width: 0.7px;
}

.section-title:not(.with-instructions) {
    margin-bottom: 20px;
}

.section-title.light {
    color: #888888;
}

.instructions {
    margin-bottom: 15px;
}