.Map {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 55px;
    background: white;
    pointer-events: none;
}

.withMap .Map {
    pointer-events: initial;
}

.Map .MapMarker {
    position: relative;
    left: -31px;
    top: -56px;
    outline: none;
}

.Map .MapHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(229, 229, 229, 0.5);
    backdrop-filter: blur(3px);
    text-transform: uppercase;
}

.Map .MapHeader .container {
    display: flex;
    align-items: stretch;
}

.Map .MapHeader .container .button {
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Map .MapHeader .container > div {
    flex: 1;
    padding: 20px;
    padding-left: 0;
}

.Map .MapHeader h3 {
}

.Map .MapHeader h4 {
    font-weight: 300;
}