body, html {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    min-height: 100%;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.flex-1 {
    flex: 1
}

img, canvas {
    max-width: 100%;
}

/*#root > div.hasOverlay {
    filter: blur(4px);
}*/

* {
    box-sizing: border-box;
}

.separated-list > *:not(:last-child) {
    border-bottom: solid lightgray 1px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table td {
    padding: 7px;
}

.container {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.container.width-100 {
    width: 100%;
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
    min-width: 0;
    -webkit-appearance: none;
}

div#react-confirm-alert {
    z-index: 1051;
    position: absolute;
}

.react-confirm-alert-overlay {
    -webkit-animation: react-confirm-alert-fadeIn 0.3s 0s forwards;
    animation: react-confirm-alert-fadeIn 0.3s 0s forwards;
    background: rgba(0, 0, 0, 0.3);
}

.react-confirm-alert-body p {
    font-size: 21px;
    line-height: 30px;
}

.react-confirm-alert-body {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    padding: 16px;
    overflow: auto;
    background-color: white;
    color: #404040;
    width: 90vw;
    max-width: 600px;
    font-weight: 300;
    border-radius: 4px;
}

.react-confirm-alert-body textarea, .react-confirm-alert-body input {
    margin-top: 10px;
    background: #fbfbfb;
    padding: 5px;
    border-radius: 4px;
}

.react-confirm-alert-button-group {
    display: block;
    text-align: center;
}

.react-confirm-alert-blur {
    -webkit-filter: none;
    filter: none;
}

.react-confirm-alert-blur > :first-child {
    -webkit-filter: url(#gaussian-blur);
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.pull-right {
    float: right !important;
}

.margin-top {
    margin-top: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.app-loading {
    margin: 0 auto;
    border-radius: 10px;
    padding: 1px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1052;
}

.app-loading span {
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: #011146;
    width: 0;
    animation: borealisBar 2s linear infinite;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
}

.RouteWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 55px;
    overflow: auto;
    transition: background-color 0.3s;
}

.RouteWrapper:not(.see-through) {
    background-color: rgba(255, 255, 255, 1);
}

.RouteWrapper > * {
    pointer-events: initial;
}

.withMap .RouteWrapper {
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0);
}

.hideWithMap, .hideWithoutMap {
    transition: opacity 0.5s;
}

.withMap .hideWithMap {
    opacity: 0;
}

.hideWithoutMap {
    opacity: 0;
    pointer-events: none;
}

.withMap .hideWithoutMap {
    opacity: 1;
    pointer-events: initial;
}

.section-placeholder {
    text-align: center;
    min-height: 80px;
    margin: 15px 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: #fbfbfb;
    border: dashed 1px #e8e8e8;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 15px;
}

.cta {
    display: flex;
    padding: 10px 15px;
    background-color: #ff862a;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.cta:hover {
    opacity: 0.8;
}

.cta p {
    font-weight: bold;
    flex: 1;
    margin: 0;
    color: white;
    margin-top: 2px;
}

.notification-container {
    left: 0;
    width: auto;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}

.notification-container .notification {
    margin-top: 0;
    margin-bottom: 15px;
}

.notification-enter {
    transform: translate3d(0, -100%, 0);
}

.notification-leave.notification-leave-active {
    transform: translate3d(0, -100%, 0);
}

.ios .page_header_padding {
    padding-top: 25px;
}