.Sessao {
    margin-top: 0;
    transition: margin-top 0.3s;
}

.Sessao .header {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 30vh;
    border-radius: 0;
    margin: 0;
    transition: margin 0.3s, border-radius 0.6s, height 0.3s;
}

.Sessao .header .header_content {
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Sessao .header .header_content .button {
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.Sessao .header .header_content .header_text {
    color: white;
    padding-bottom: 30px;
    text-transform: uppercase;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.66);
}

.Sessao .header .header_content .header_text h3 {
    font-weight: normal;
    line-height: 1.1;
}

.Sessao .header .header_content .header_text p {
    font-weight: 200;
}

.Sessao .content {
    padding: 25px 0;
}


.Sessao .content .sessionInformation:not(:last-child) {
    margin-bottom: 40px;
}


.Sessao .content .sessionInformation .sectionTitle {
    padding-bottom: 8px;
    border-bottom: 1px solid #c6c6c8;
    margin-bottom: 15px;
}

.Sessao .content .sessionInformation .sectionTitle h3 {
    text-transform: uppercase;
    color: #011146;
}

.Sessao .content .sessionInformation p {
    font-size: 16px;
    line-height: 1.5;
}


.Sessao .content .sessionInformation .local {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.Sessao .content .sessionInformation .local .texto {
    flex: 1
}

.Sessao .content .sessionInformation .local .texto p {
    margin: 0;
}

.Sessao .content .sessionInformation .oradores .orador {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c6c6c8;
    padding: 10px 0;
    cursor: pointer;
    margin-right: -20px;
}

.Sessao .content .sessionInformation .oradores .orador .image {
    width: 60px;
    height: 60px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
}

.Sessao .content .sessionInformation .oradores .orador h4 {
    flex: 1;
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 5px;
}

/*
* Estilos para quando o mapa está a ser apresentado
*/

.withMap .Sessao {
    margin-top: calc(75vh - 70px);
}

.withMap .Sessao .header {
    margin: 0 20px;
    border-radius: 15px;
    overflow: hidden;
    height: 25vh;
    cursor: pointer;
}

.withMap .Sessao .header .header_content .button {
    opacity: 0;
    pointer-events: none;
}
