.Loading {
    padding: 40px 0;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loading svg {
    fill: #dddddd;
}
