.Login {
    padding: 35px 15px;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.Login .bemVindo {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

.Login .logo {
    width: 250px;
    display: block;
    margin: auto;
}

.Login form {

}

.Login > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    width: 100%;
}