.SlidingToggle {
    padding: 10px 15px;
    border-radius: 15px;
    background: #f5f5f5;
}

.SlidingToggle .inner {
    position: relative;
    height: 30px;
}

.SlidingToggle .inner .slider {
    min-width: 0;
    position: absolute;
    height: 100%;
    transition: margin-left 0.3s;
    min-height: 0;
}

.SlidingToggle .inner .touch_area {
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: stretch;
    height: 100%;
}

.SlidingToggle .inner .touch_area .option {
    flex: 1;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
    white-space: nowrap;
    font-size: 12px;
}

.SlidingToggle .inner .touch_area .option.selected {
    color: white;
}