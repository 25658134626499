.Modal {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1040;
    position: fixed;
    overflow: hidden;
}

.Modal .background_top {
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    position: absolute;
    backdrop-filter: blur(2px);
    height: 100px;
}

.Modal .background {
    background-color: rgba(255, 255, 255, 0.7);
    top: 100px;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
}

.Modal:not(:first-child) .background_top {
    display: none;
}

.Modal:not(:first-child) .background {
    top: 0;
    background-color: rgba(255, 255, 255, 0.3);
}


.Modal .content_wrapper {
    background: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: calc(100% - 30px);
    max-width: 450px;
    margin: auto;
    margin-top: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 -1px 3px -2px rgba(73, 73, 73, 0.7);
    position: relative;
}

.Modal:nth-child(2) .content_wrapper {
    margin-top: 45px;
    height: calc(100% - 45px);
}

.Modal:nth-child(3) .content_wrapper {
    margin-top: 60px;
    height: calc(100% - 60px);
}

.ios .Modal .content_wrapper, .android .Modal .content_wrapper {
    margin-top: 55px;
    height: calc(100% - 55px);
}

.ios .Modal:nth-child(2) .content_wrapper, .android .Modal:nth-child(2) .content_wrapper {
    margin-top: 70px;
    height: calc(100% - 70px);
}

.ios .Modal:nth-child(3) .content_wrapper, .android .Modal:nth-child(3) .content_wrapper {
    margin-top: 85px;
    height: calc(100% - 85px);
}

.Modal .modal_header {
    background-color: #f5f5f5;
    box-shadow: 0 2px 2px rgba(73, 73, 73, 0.2);
    z-index: 1;
}

.Modal .modal_header .container {
    display: flex;
    align-items: stretch;
}

.Modal .modal_header .container .button {
    padding: 12px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Modal .modal_header .container > div {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 0;
}

.Modal .modal_header h3 {
    margin-top: 2px;
    text-transform: uppercase;
    padding: 10px 0;
    padding-right: 18px;
}

.Modal .modal_content {
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.Modal .modal_content:not(.fullscreen) {
    padding: 30px 0;
}