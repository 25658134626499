.Home {
    padding: 25px 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0.7) 100%);
    min-height: 100%;
    transition: background 1s;
}

.Home .HomeHeader {
    flex-direction: row;
    align-items: center;
}

.Home .HomeHeader .qrcode {
    height: 45px;
    width: auto;
    display: block;
}

.Home .HomeHeader .avatar {
    width: 50px;
    border-radius: 50%;
    margin-left: 15px;
    display: block;
}

.Home .HomeHeader p {
    font-weight: normal;
    margin-top: 3px;
    color: initial;
}

.HomeCarousel {
    overflow: hidden;
}

.HomeCarousel .itemsWrapper {
    /*padding-right: 6%;*/
    overflow-x: initial;
}

.HomeCarousel .wrapper {
    width: 85%;
}

.HomeCarousel .itemWrapper:first-child {
    /*margin-left: calc(50vw - 225px);*/
    /*max-width: calc(450px + 50vw - 225px);*/
}

.HomeCarousel .itemWrapper {
    /*max-width: 450px;*/
}

.HomeCarousel .Card {
    padding-bottom: 56%;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.HomeCarousel .Card .placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f9f9f9
}

.HomeCarousel .Card .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 6.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.HomeCarousel .Card .content h4, .HomeCarousel .Card .content h5 {
    text-transform: uppercase;
    color: white;
}

.HomeCarousel .Card .content h4 {
    font-size: 15px;
    font-weight: 600;
}

.HomeCarousel .Card .content h5 {
    font-size: 14px;
    margin-top: 3px;
    font-weight: 300;
}

.HomeCarousel {
    margin-top: 0;
}

.Noticia p {
    font-size: 16px;
    line-height: 1.5;
}

.Home {
    transition: margin-top 0.5s;
}

.withMap .Home {
    /*margin-top: calc(100vh - 460px);*/
    pointer-events: none;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0.0) 100%);;
}

.withMap .Home .HomeCarousel {
    pointer-events: initial;
}