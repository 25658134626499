.field-radio-group label {
    font-weight: 300;
    width: 100%;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.field-radio-group .radio {
    padding: 2px 0;
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
    min-width: 0;
    -webkit-appearance: none;
}

.has-error input:not([type=checkbox]):not([type=radio]) {
    border-bottom: 3px solid rgba(223, 92, 74, 0.2);
}

.has-error input:not([type=checkbox]):not([type=radio]):focus {
    border-bottom: 3px solid #df5c4a;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input, textarea, select {
    font-family: inherit;
    font-size: inherit;
}

textarea {
    height: auto;
    resize: vertical;
    min-height: 4em;
}

.customFieldInput {
    position: relative;
    margin-top: 15px;
}

.customFieldInput input:not([type=checkbox]):not([type=radio]), .customFieldInput textarea {
    width: 100%;
    font-weight: 300;
    outline: none;
    -webkit-transition: border-bottom .3s;
    -o-transition: border-bottom .3s;
    transition: border-bottom .3s;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    border-bottom: 3px solid rgba(0, 0, 0, .12);
    display: block;
    font-size: 16px;
}

.customFieldInput input:not([type=checkbox]):not([type=radio]):focus, .customFieldInput input:not([type=checkbox]):not([type=radio]):valid, .customFieldInput textarea:focus {
    border-bottom: 3px solid #0E309F;
    color: #0E309F
}

.customFieldInput:not(:first-child) {
    margin-top: 25px;
}

.customFieldInput label:not(:empty) {
    position: absolute;
    top: -12px;
    left: 2px;
    text-align: left;
    display: block;
    width: 100%;
    height: 40px;
    line-height: 62px;
    font-size: 13px;
    background: transparent;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 auto;
    cursor: text;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
}

.customFieldInput input:focus {
    background-position: left bottom;
    background-size: 100% 1px;
}

.customFieldInput input:valid + label, .customFieldInput input:focus + label {
    height: 15px;
    line-height: 15px;
    font-size: 11px;
    left: 0;
}

.error-detail {
    display: none;
}

/*Radio buttons*/

[type="radio"] {
    position: absolute;
    left: -9999px;
}

[type="radio"] + span {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

[type="radio"] + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

[type="radio"] + span:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #009BDF;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}