.CabecalhoPagina {
    background-color: white;
    box-shadow: 0 2px 2px rgba(73, 73, 73, 0.2);
    z-index: 1;
}

.CabecalhoPagina .container {
    display: flex;
    align-items: stretch;
}

.CabecalhoPagina .container > div {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding-left: 0;
}

.CabecalhoPagina .container .button {
    padding: 12px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.CabecalhoPagina h3 {
    text-transform: uppercase;
    padding-right: 68px;
    text-align: center;
    font-size: 14px;
    flex: 1;
}