.List {

}

.List .ListItem.desafio {
    padding: 15px;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    border: solid 1px #f2f2f2;
    background: white;
    display: flex;
    align-items: center;
}

.List .ListItem.desafio.clickable {
    cursor: pointer;
}

.List .ListItem.desafio > div:first-of-type {
    flex: 1
}

.List .ListItem.desafio > svg {
    margin-left: 10px;
}

.List .ListItem.desafio > div:last-child span {
    color: #50b17a;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    line-height: 12px;
}

.List .ListItem.desafio > div:last-child span small {
    display: block;
    font-weight: bold;
}

.List .ListItem.desafio .icon {
    margin-right: 10px;
}

.List .ListItem.desafio:not(:last-child) {
    margin-bottom: 15px;
}

.List .ListItem.download {
    padding: 15px;
    background: #f3f4f5;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.List .ListItem.download h4 {
    font-weight: normal;
    flex: 1
}

.List .ListItem.download:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.List .ListItem.download:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.List .ListItem.session {
    display: flex;
    align-items: center;
    padding: 14px 0;
}

.List .ListItem.session:not(:last-child) {
    border-bottom: #c6c6c8 1px solid;
}

.List .ListItem.session div {
    flex: 1;
}

.List .ListItem.session h3 {
    font-weight: normal;
}

.List .ListItem.session h4 {
    font-size: 13px;
    font-weight: 200;
}

.List .ListItem.accordion.collapsible .header {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-bottom: 0;
    background: #f3f4f5;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;
}

.List .ListItem.accordion.collapsible .header h3 {
    flex: 1
}

.List .ListItem.accordion.collapsible:not(:first-child) {
    margin-top: 25px;
}

.List .ListItem.accordion.collapsible {
    position: relative;
    margin-bottom: 15px;
}

.List .ListItem.accordion.collapsible:after {
    content: "";
    margin-bottom: 15px;
    left: 0;
    right: 0;
    position: absolute;
    height: 15px;
    background: #f3f4f5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.List .ListItem.accordion.collapsible__trigger svg {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: transform .3s;
    -o-transition: transform .3s;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin-left: 10px;
}

.List .ListItem.accordion.collapsible__trigger.is-open svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.List .ListItem.accordion.collapsible__contentOuter {
    padding: 0;
}

.List .ListItem.accordion .innerTitle {
    min-height: 15px;
    background: #f3f4f5;
}

.List .ListItem.accordion .innerTitle:not(:empty) {
    padding: 15px;
}

.List .ListItem.input {
    overflow: hidden;
}

.List .ListItem.input:not(:last-child) {
    margin-bottom: 1px;
}

.List .ListItem.input:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.List .ListItem.input:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.List .ListItem.input input:not([type=checkbox]):not([type=radio]), .List .ListItem.input select {
    border: none;
    width: 100%;
    padding: 15px;
    background: #f3f4f5;
    outline: none;
}

.List .ListItem.input select {
    padding-left: 11px;
}

.List .ListItem.toggle {
    position: relative;
    overflow: hidden;
}

.List .ListItem.toggle input {
    position: absolute;
    left: -100px;
}

.List .ListItem.toggle input:checked + span {
    background-color: #4db750;
    color: white;
}

.List .ListItem.toggle span {
    padding: 15px;
    background: #f3f4f5;
    display: block;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.1s;
}

.List .ListItem.toggle:not(:last-child) {
    margin-bottom: 4px;
}