.DesbloquearDesafio .DesbloquearDesafioHeader {
    background: rgba(250, 250, 250, 0.8);
}

.DesbloquearDesafio .DesbloquearDesafioHeader.fixed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    backdrop-filter: blur(3px);
}

.DesbloquearDesafio .DesbloquearDesafioHeader .container {
    display: flex;
    align-items: stretch;
}

.DesbloquearDesafio .DesbloquearDesafioHeader .container .button {
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.DesbloquearDesafio .DesbloquearDesafioHeader .container > div {
    flex: 1;
    padding: 20px;
    padding-left: 0;
}

.DesbloquearDesafio .DesbloquearDesafioHeader h3 {
    text-transform: uppercase;
}

.DesbloquearDesafio .QrCode section {
    height: calc(100vh - 55px);
}

.DesbloquearDesafio h2 {
    margin-left: 30px;
    margin-right: 30px;
}

.DesbloquearDesafio .codigo_manual {
    padding: 14px;
    text-align: center;
    border-radius: 90px;
    border: 2px solid gray;
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    margin: 0 auto;
    outline: none;
    min-width: 85%;
    margin-top: 18px;
}

.DesbloquearDesafio .legacy {
    padding: 70px 0;
}