.Perfil {
    padding: 25px 0;
}

.Perfil .interesses .interesse {
    padding: 5px 10px;
    background: #f4f4f4;
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 5px;
    margin-right: 5px;
    display: inline-block;
}

.CabecalhoPerfil {
    display: flex;
    align-items: center;
    justify-content: center;
}

.CabecalhoPerfil .avatar {
    border-radius: 50%;
    width: 130px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
}

.CabecalhoPerfil .col_direita {
    padding-left: 20px;
}

.CabecalhoPerfil .col_direita h2 {
    font-weight: bold;
    font-size: 26px;
}

.CabecalhoPerfil .col_direita h3 {
    font-weight: normal;
    font-size: 16px;
    color: #7A8FA6;
    line-height: 0.95;
    margin-top: 5px;
}

.CabecalhoPerfil .col_direita p {
    color: #7A8FA6;
    margin-top: 5px;
}

.CabecalhoPerfil .btn {
    margin-top: 6px;
}